const myStyle = theme => ({
  blockContainer: {
    position: 'relative',
    zIndex: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
  container: {
    position: 'relative',
  },
  title: {
    position: 'relative',
    textAlign: 'center',
    color: theme.palette.yellow.main,
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      '& h1': {
        fontSize: '2.3rem',
      },
    },
  },
  title2: {
    textAlign: 'center',
    fontFamily: 'Museo',
    fontSize: '2.8rem',
    color: theme.palette.white.main,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      '& h1': {
        fontSize: '2.3rem',
      },
    },
  },
  map: {
    width: '100%',
    marginTop: -100,
    [theme.breakpoints.down('sm')]: {
      marginTop: -50,
    },
    '& .map-map': {
      transition: 'opacity 1s ease-in-out',
      opacity: 0,
    },
    '& .map-white1': {
      transition: 'opacity 0.6s ease-in-out 1s',
      opacity: 0,
    },
    '& .map-white2': {
      transition: 'opacity 0.6s ease-in-out 1.3s',
      opacity: 0,
    },
    '& .map-white3': {
      transition: 'opacity 0.6s ease-in-out 1.6s',
      opacity: 0,
    },
    '& .map-yellow1': {
      transition: 'opacity 0.6s ease-in-out 1.9s',
      opacity: 0,
    },
    '& .map-yellow2': {
      transition: 'opacity 0.6s ease-in-out 2.1s',
      opacity: 0,
    },
    '& .map-yellow3': {
      transition: 'opacity 0.6s ease-in-out 2.4s',
      opacity: 0,
    },
    '& .show .map-map': {
      opacity: 1,
    },
    '& .show .map-white1': {
      opacity: 1,
    },
    '& .show .map-white2': {
      opacity: 1,
    },
    '& .show .map-white3': {
      opacity: 1,
    },
    '& .show .map-yellow1': {
      opacity: 1,
    },
    '& .show .map-yellow2': {
      opacity: 1,
    },
    '& .show .map-yellow3': {
      opacity: 1,
    },
  },
  captions: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      fontSize: '1rem !important',
    },
  },
  item: {
    width: '100%',
    '&:nth-child(1)': {
      color: theme.palette.white.main,
    },
    '&:nth-child(2) p': {
      color: `${theme.palette.yellow.main}`,
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },
  },
  dot: {
    height: 20,
    width: 20,
    marginRight: theme.spacing(1),
    '@media (max-width:1280px)': {
      height: 15,
      width: 15,
    },
  },
  titleItem: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem !important',
    },
  }
});

export default myStyle;