import React from 'react';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import uniqueId from 'lodash/uniqueId';

import MGrid from '@material-ui/core/Grid';

import Item from './Item';

class Grid extends React.PureComponent {
  render() {
    const list = [];
    this.parseList(list, [this.props.children]);
    return (
      <MGrid
        className={`grid grid-spacing-${this.props.spacing} ${this.props.className}`}
        style={{ lineHeight: this.props.lineHeight }}
        container
        spacing={this.props.spacing}
        justifyContent={this.props.justifyContent}
        direction={this.props.direction}
        alignItems={this.props.alignItems}
        alignContent={this.props.alignContent}
        wrap={this.props.wrap}
      >
        {list}
      </MGrid>
    );
  }

  renderChild(key, content) {
    return (
      <Item
        className={this.props.itemClassName}
        xs={this.props.xs}
        sm={this.props.sm}
        md={this.props.md}
        lg={this.props.lg}
        xl={this.props.xl}
        key={key}
      >
        {content}
      </Item>
    );
  }

  parseList(list, children) {
    forEach(children, child => {
      if (!isNil(child)) {
        if (React.isValidElement(child)) {
          if (child.type === React.Fragment) {
            if (!isNil(child.props.children)) {
              this.parseList(list, [child.props.children]);
            }
          } else if (child.type === Item) {
            list.push(child);
          } else {
            list.push(this.renderChild(child.key, child));
          }
        } else if (isArray(child)) {
          this.parseList(list, child);
        } else if (typeof child === 'string') {
          list.push(this.renderChild(uniqueId('GridItem-'), child));
        }
      }
    });
  }
}

Grid.Item = Item;
Grid.defaultProps = {
  className: '',
  itemClassName: '',
  spacing: 2,
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  alignContent: 'stretch',
  direction: 'row',
  wrap: 'wrap',
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  lineHeight: 0,
};

Grid.propTypes = {
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  spacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  justifyContent: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  alignContent: PropTypes.oneOf(['stretch', 'center', 'flex-start', 'flex-end', 'space-between', 'space-around']),
  wrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
  xs: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  children: PropTypes.any,
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Grid;
