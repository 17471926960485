/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import parse from '../../utils/Parser';
import { InView } from 'react-intersection-observer';

import myStyle from './styles';

class B0Intro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inView0: false,
    };
    this.onView = this.onView.bind(this);
  }

  onView(name, inView) {
    this.setState(prevState => ({ [name]: inView || prevState[name] }));
  }

  render() {
    const { inView0 } = this.state;
    const { classes, data, baseUrl } = this.props;
    return (
      <Box className={classes.blockContainer}>
        <InView onChange={inView => this.onView('inView0', inView)} triggerOnce threshold={0}> 
          <Container className={classes.container}>
            <img src={`${baseUrl}/common/${data.b0intro.logo}`} alt="" className={classes.logo} />
            <Box className={classes.title}>
              <img src={`${baseUrl}/blocks/${data.b0intro.image}`} alt="" />
              <Box className={classes.text}>
                <Typography variant='h1' >{parse(data.b0intro.title1)}</Typography>
                <Typography variant='h1' >{parse(data.b0intro.title2)}</Typography>
                <Typography variant='h1' className={classes.text3}>{parse(data.b0intro.title3)}</Typography>
              </Box>
            </Box>
            <img src={`${baseUrl}/blocks/${data.b0intro.illustration}`} alt="" className={`${classes.montgolfiere} ${inView0 ? 'show' : ''}`} />
          </Container>
        </InView>
      </Box>
    );
  }
}

B0Intro.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  baseUrl: PropTypes.string,
};

export default withStyles(myStyle)(B0Intro);
