import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import Anchor from '../../components/Anchor';
import Grid from '../../components/Grid';
import { InView } from 'react-intersection-observer';

import parse from '../../utils/Parser';
import myStyle from './styles';

class B1Reason extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inView1: false,
      inView2: false, 
    };
    this.onView = this.onView.bind(this);
  }

  onView(name, inView) {
    this.setState(prevState => ({ [name]: inView || prevState[name] }));
  }

  renderContent() {
    const { inView1, inView2 } = this.state;
    const { classes, data, baseUrl } = this.props;

    return (
      <Container maxWidth="lg" className={classes.container}>
        <Box className={classes.title}>
          <Typography variant="h1">{parse(data.b1reason.title)}</Typography>
        </Box>
        <Typography variant="body2" className={classes.desc}>
          {parse(data.b1reason.desc)}
        </Typography>
        <Typography variant="body2" className={classes.emphazise}>
          {parse(data.b1reason.emphazise)}
        </Typography>
        <Box className={classes.content}>
          <Box className={classes.subtitle}>
            <Typography variant="h2">{parse(data.b1reason.subtitle)}</Typography>
          </Box>
          <InView onChange={inView => this.onView('inView1', inView)} triggerOnce threshold={0.5}> 
            <Grid alignItems="flex-start" justifyContent="center" className={classes.grid}>
              <Grid.Item key="item1" className={`${classes.item} ${classes.item1} ${inView1 ? 'show' : ''}`}>
                <img src={`${baseUrl}/blocks/${data.b1reason.icon1}`} alt="" />
                <Typography variant="body2" className={classes.feature}>
                  {parse(data.b1reason.feature1)}
                </Typography>
                <Typography variant="body1" className={classes.descItem}>
                  {parse(data.b1reason.desc1)}
                </Typography>
              </Grid.Item>
              <Grid.Item key="item2" className={`${classes.item} ${classes.item2} ${inView1 ? 'show' : ''}`}>
                <img src={`${baseUrl}/blocks/${data.b1reason.icon2}`} alt="" />
                <Typography variant="body2" className={classes.feature}>
                  {parse(data.b1reason.feature2)}
                </Typography>
                <Typography variant="body1" className={classes.descItem}>
                  {parse(data.b1reason.desc2)}
                </Typography>
              </Grid.Item>
              <Grid.Item key="item3" className={`${classes.item} ${classes.item3} ${inView1 ? 'show' : ''}`}>
                <img src={`${baseUrl}/blocks/${data.b1reason.icon3}`} alt="" />
                <Typography variant="body2" className={classes.feature}>
                  {parse(data.b1reason.feature3)}
                </Typography>
                <Typography variant="body1" className={classes.descItem}>
                  {parse(data.b1reason.desc3)}
                </Typography>
              </Grid.Item>
            </Grid>
          </InView>
        </Box>
        <InView onChange={inView => this.onView('inView2', inView)} triggerOnce threshold={0}> 
          <img src={`${baseUrl}/blocks/${data.b1reason.illustration}`} alt="" className={`${classes.plane} ${inView2 ? 'show' : ''}`} />
        </InView>
      </Container>
    );
  }

  render() {
    const { classes, baseUrl, data, sectionRef } = this.props;
    return (
      <Box ref={sectionRef} className={classes.blockContainer}>
          <Anchor id="reason" />
          <img src={`${baseUrl}/blocks/${data.b1reason.image}`} alt="" className={classes.sculpture} />
          {this.renderContent()}
      </Box>
    );
  }
}

B1Reason.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  baseUrl: PropTypes.string,
  sectionRef: PropTypes.object,
};

export default withStyles(myStyle)(B1Reason);
