import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import { InView } from 'react-intersection-observer';

import Anchor from '../../components/Anchor';
import Grid from '../../components/Grid';
import parse from '../../utils/Parser';
import myStyle from './styles';

class B7Satisfaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inView11: false,
    };
    this.onView = this.onView.bind(this);
  }

  onView(name, inView) {
    this.setState(prevState => ({ [name]: inView || prevState[name] }));
  }
  
  renderText(text, idx) {
    const { classes } = this.props;
    return(
      <Typography key={idx} variant="body1" className={classes.text}>{parse(text)}</Typography>
    );
  }

  render() {
    const { inView11 } = this.state;
    const { classes, data, baseUrl } = this.props;
    return (
      <Box className={classes.blockContainer}>
        <Anchor id="satisfaction" />
        <Container maxWidth="lg" className={classes.container}>
          <Grid spacing={4} alignItems="center" justifyContent="center" className={classes.mainGrid}>
            <Grid.Item key="img" sm={12} md={4} className={classes.gridItem}>
              <InView onChange={inView => this.onView('inView11', inView)} triggerOnce threshold={0.5}> 
                <img src={`${baseUrl}/blocks/${data.b7satisfaction.image}`} alt="" className={`${classes.image} ${inView11 ? 'show' : ''}`} />
              </InView>
            </Grid.Item>
            <Grid.Item key="textcontent" sm={12} md={8} className={classes.textContent}>
              <Grid spacing={0} alignItems="flex-end" className={classes.secondaryGrid}>
                <Grid.Item key="desc" className={classes.desc}>
                  <Typography variant="h3" className={classes.title}>{parse(data.b7satisfaction.title)}</Typography>
                  <Box>
                    {data.b7satisfaction.text.map((text, idx) => this.renderText(text, idx))}
                  </Box>
                </Grid.Item>
                <Grid.Item key="btn" className={classes.button}>
                  <ButtonBase
                    component="a"
                    target="_blank"
                    href={`${baseUrl}${data.b7satisfaction.url}`}
                    focusRipple
                    className={classes.button}
                  >
                    <Typography variant="button">{parse(data.b7satisfaction.btn)}</Typography>
                  </ButtonBase>
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </Box>
    );
  }
}

B7Satisfaction.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  baseUrl: PropTypes.string,
};

export default withStyles(myStyle)(B7Satisfaction);
