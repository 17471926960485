import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Box, Dialog, Typography } from '@material-ui/core';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '../../components/Grid';
import { InView } from 'react-intersection-observer';

import parse from '../../utils/Parser';
import myStyle from './styles';

class B6Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: '',
      InView10: false,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  onView(name, inView) {
    this.setState(prevState => ({ [name]: inView || prevState[name] }));
  }

  handleClickOpen(id) {
    this.setState({ open: id });
  }

  handleClose() {
    this.setState({ open: '' });
  }

  renderAdmin(item, idx) {
    const { classes, baseUrl } = this.props;

    return(
      <Grid.Item key={idx} className={classes.admin}>
        <Box className={`${classes.imageWrapper} ${item.content ? '' : 'inactive'}`} onClick={() => this.handleClickOpen(idx)}>
          <img src={`${baseUrl}/administrateurs/${item.image}`} alt="" className={classes.image} />
          {item.content && 
            <img src={`${baseUrl}/common/pastille3.png`} alt="" className={classes.dot} />
          }
          {item.content && 
            <img src={`${baseUrl}/common/pastille4.png`} alt="" className={`${classes.dot} ${classes.dotHover}`} />
          }
        </Box>
        <Typography variant="body2" className={classes.name}>{parse(item.name)}</Typography>
        <Typography variant="body2" className={classes.role}>{parse(item.role)}</Typography>
      </Grid.Item>
    );
  }

  renderText(text, idx) {
    const { classes } = this.props;

    return (
      <Typography key={idx*3} variant="body1" className={classes.content}>{parse(text)}</Typography>
    );
  }

  renderDialog(item, idx) {
    const { open } = this.state;
    const { classes, baseUrl } = this.props;

    return(
        item.content ? 
          <Dialog
            key={idx}
            fullWidth
            maxWidth="md"
            onClose={this.handleClose}
            open={open === idx}
            className={classes.dialogContainer}
          >
            <Grid spacing={0} direction="row" wrap="nowrap" justifyContent="space-around" alignItems="center" className={classes.popup}>
              <IconButton aria-label="close" className={classes.closePopup} onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
              <Grid.Item className={classes.imageWrapperPopup} >
                <img src={`${baseUrl}/administrateurs/${item.image}`} alt="" className={classes.imagePopup} />
                <img src={`${baseUrl}/common/pastille3.png`} alt="" className={classes.imageDotPopup} />
              </Grid.Item>
              <Grid.Item className={classes.contentPopup} >
                <MuiDialogContent>
                  <Typography variant="h4" className={classes.namePopup}>{parse(item.name)}</Typography>
                  {item.content.map((text, idx) => this.renderText(text, idx))
                  }
                </MuiDialogContent>
              </Grid.Item>
            </Grid>
          </Dialog>
        : null
    );
  }

  renderContent() {
    const { classes, data } = this.props;

    return (
      <Container maxWidth="lg" className={classes.container}>
        <Box className={classes.title}>
          <Typography variant="h1">{parse(data.b6admin.title)}</Typography>
        </Box>
        <Grid spacing={0} direction="row" wrap="wrap" justifyContent="center" className={classes.admins}>
          {data.b6admin.admins.map((item, idx) => this.renderAdmin(item, idx))}
        </Grid>
      </Container>
    );
  }

  render() {
    const { inView10 } = this.state;
    const { classes, baseUrl, data } = this.props;
    return (
      <Box className={classes.blockContainer}>
        {this.renderContent()}
        {data.b6admin.admins.map((item, idx) => this.renderDialog(item, idx))}
        <img src={`${baseUrl}/blocks/${data.b6admin.illustration1}`} alt="" className={classes.building} />
        <InView onChange={inView => this.onView('inView10', inView)} triggerOnce threshold={1}> 
          <img src={`${baseUrl}/blocks/${data.b6admin.illustration2}`} alt="" className={`${classes.kite} ${inView10 ? 'show' : ''}`} />
        </InView>
      </Box>
    );
  }
}

B6Admin.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  baseUrl: PropTypes.string,
};

export default withStyles(myStyle)(B6Admin);
