import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import myStyle from './styles';

class Anchor extends React.PureComponent {
  render() {
    const { classes, id } = this.props;
    return <div id={id} className={classes.container} />;
  }
}

Anchor.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
};

export default withStyles(myStyle)(Anchor);
