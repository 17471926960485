import { createTheme } from '@material-ui/core/styles';
const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL;

export default createTheme({
  url: s => `${BASE_URL}${s}`,
  shape: {
    borderRadius: 20,
  },
  global: {
    maxWidth: 1366,
  },
  palette: {
    primary: {
      main: '#005263',
      light: '#0098A9',
      lighter: '#009BAC',
      ultralight: '#00B2B8',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FFF',
      contrastText: '#000',
    },
    white: {
      main: '#FFF',
      contrastText: '#000',
    },
    black: {
      main: '#000',
      contrastText: '#FFF',
    },
    grey: {
      main: '#EFECEC',
      dark: '#080808',
      contrastText: '#000',
    },
    blue: {
      main: '#163B61',
      dark: '#122952',
      light: '#5ECFD0',
      ultralight: '#C7EDEF',
      sky: '#E0F0F3',
      pale: '#6CC4CD',
      contrastText: '#FFF',
    },
    yellow: {
      main: '#FFD600',
      light: '#FFE698',
      contrastText: '#FFF',
    },
  },
  typography: {
    fontFamily: '"Bely", serif',
    h1: {
      fontFamily: '"Bely", serif',
      fontSize: '3.6875rem',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 1.188,
      '@media (max-width:1280px)': {
        fontSize: '2rem',
      },
    },
    h2: {
      fontFamily: '"Bely", serif',
      fontSize: '2.4375rem',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 1.188,
      '@media (max-width:1280px)': {
        fontSize: '2rem',
      },
    },
    h3: {
      fontFamily: '"Bely", serif',
      fontSize: '1.4375rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.1,
      '@media (max-width:1280px)': {
        fontSize: '1.2rem',
      },
    },
    h4: {
      fontFamily: '"Bely", serif',
      fontSize: '1.25rem',
      fontWeight: 700,
      letterSpacing: '0.02375rem',
      lineHeight: 1.181,
      '@media (max-width:1280px)': {
        fontSize: '1.8rem',
      },
    },
    // h5: {
    //   fontFamily: 'Bely, serif',
    //   fontSize: '1.875rem',
    //   fontWeight: 700,
    //   letterSpacing: 0,
    //   lineHeight: 1,
    //   '@media (max-width:960px)': {
    //     fontSize: '1.5rem',
    //   },
    //   '@media (max-width:600px)': {
    //     fontSize: '1.2rem',
    //   },
    // },
    // h6: {
    //   fontFamily: 'Bely, serif',
    //   fontSize: '0.8rem',
    //   fontWeight: 400,
    //   letterSpacing: 0,
    //   lineHeight: 1.25,
    // },
    body1: {
      fontFamily: '"Museo", Arial, sans-serif',
      fontSize: '0.9rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.3,
      '@media (max-width:1280px)': {
        fontSize: '0.8rem',
      },      
    },
    body2: {
      fontFamily: '"Museo", Arial, sans-serif',
      fontSize: '1.1rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.3,
      '@media (max-width:1280px)': {
        fontSize: '0.9rem',
      },
    },
    // subtitle1: {
    //   fontFamily: '"TTNorms", Arial, sans-serif',
    //   fontSize: '0.75rem',
    //   fontWeight: 500,
    //   letterSpacing: 0,
    //   lineHeight: 1.45,
    // },
    // subtitle2: {
    //   fontFamily: '"TTNorms", Arial, sans-serif',
    //   fontSize: '0.5rem',
    //   fontWeight: 400,
    //   letterSpacing: 0,
    //   lineHeight: 1.35,
    // },
    button: {
      fontFamily: '"Museo", Arial, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 900,
      letterSpacing: '0.00125rem',
      lineHeight: 1.1,
      textTransform: 'initial',
      display: 'inline-block',
      textAlign: 'left',
    },
    // overline: {
    //   fontFamily: '"TTNorms", Arial, sans-serif',
    //   fontSize: '0.69rem',
    //   fontWeight: 500,
    //   letterSpacing: 0,
    //   lineHeight: 1.27,
    // },
    // caption: {
    //   fontFamily: '"TTNorms", Arial, sans-serif',
    //   fontSize: '0.8rem',
    //   fontWeight: 400,
    //   letterSpacing: 0,
    //   lineHeight: 1.2857,
    // },
  },
  overrides: {
    MuiTypography: {
      button: {
        textAlign: 'center',
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
          fontSize: 16,
        },
        body: {
          height: '100%',
          backgroundColor: '#FFF',
          '& > #root': {
            height: '100%',
          },
        },
        '*::-webkit-scrollbar': {
          width: '8px',
        },
        '*::-webkit-scrollbar-track': {
          backgroundColor: 'rgba(0,0,0,0.1)',
          borderRadius: '10px',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          backgroundColor: 'rgba(68,68,68,0.8)',
        },
        '*': {
          scrollbarColor: 'rgba(68,68,68,0.8) rgba(0,0,0,0.1)',
          scrollbarWidth: 'thin',
        },
      },
    },
    MuiContainer: {
      root: {
        position: 'relative',
        paddingLeft: 16,
        paddingRight: 16,
        '@media (min-width:600px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
        '@media (min-width:960px)': {
          paddingLeft: 64,
          paddingRight: 64,
        },
        '@media (min-width:1024px)': {
          paddingLeft: 100,
          paddingRight: 100,
        },
      },
    },
  },
});

/**
// Default theme
{
   "breakpoints":{
      "keys":[
         "xs",
         "sm",
         "md",
         "lg",
         "xl"
      ],
      "values":{
         "xs":0,
         "sm":600,
         "md":960,
         "lg":1280,
         "xl":1920
      }
   },
   "direction":"ltr",
   "mixins":{
      "toolbar":{
         "minHeight":56,
         "@media (min-width:0px) and (orientation: landscape)":{
            "minHeight":48
         },
         "@media (min-width:600px)":{
            "minHeight":64
         }
      }
   },
   "overrides":{

   },
   "palette":{
      "common":{
         "black":"#000",
         "white":"#fff"
      },
      "type":"light",
      "primary":{
         "light":"#7986cb",
         "main":"#3f51b5",
         "dark":"#303f9f",
         "contrastText":"#fff"
      },
      "secondary":{
         "light":"#ff4081",
         "main":"#f50057",
         "dark":"#c51162",
         "contrastText":"#fff"
      },
      "error":{
         "light":"#e57373",
         "main":"#f44336",
         "dark":"#d32f2f",
         "contrastText":"#fff"
      },
      "warning":{
         "light":"#ffb74d",
         "main":"#ff9800",
         "dark":"#f57c00",
         "contrastText":"rgba(0, 0, 0, 0.87)"
      },
      "info":{
         "light":"#64b5f6",
         "main":"#2196f3",
         "dark":"#1976d2",
         "contrastText":"#fff"
      },
      "success":{
         "light":"#81c784",
         "main":"#4caf50",
         "dark":"#388e3c",
         "contrastText":"rgba(0, 0, 0, 0.87)"
      },
      "grey":{
         "50":"#fafafa",
         "100":"#f5f5f5",
         "200":"#eeeeee",
         "300":"#e0e0e0",
         "400":"#bdbdbd",
         "500":"#9e9e9e",
         "600":"#757575",
         "700":"#616161",
         "800":"#424242",
         "900":"#212121",
         "A100":"#d5d5d5",
         "A200":"#aaaaaa",
         "A400":"#303030",
         "A700":"#616161"
      },
      "contrastThreshold":3,
      "tonalOffset":0.2,
      "text":{
         "primary":"rgba(0, 0, 0, 0.87)",
         "secondary":"rgba(0, 0, 0, 0.54)",
         "disabled":"rgba(0, 0, 0, 0.38)",
         "hint":"rgba(0, 0, 0, 0.38)"
      },
      "divider":"rgba(0, 0, 0, 0.12)",
      "background":{
         "paper":"#fff",
         "default":"#fafafa"
      },
      "action":{
         "active":"rgba(0, 0, 0, 0.54)",
         "hover":"rgba(0, 0, 0, 0.04)",
         "hoverOpacity":0.04,
         "selected":"rgba(0, 0, 0, 0.08)",
         "selectedOpacity":0.08,
         "disabled":"rgba(0, 0, 0, 0.26)",
         "disabledBackground":"rgba(0, 0, 0, 0.12)",
         "disabledOpacity":0.38,
         "focus":"rgba(0, 0, 0, 0.12)",
         "focusOpacity":0.12,
         "activatedOpacity":0.12
      }
   },
   "props":{

   },
   "shadows":[
      "none",
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
      "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
      "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
      "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
      "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
      "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
      "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
      "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
      "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
      "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
      "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
      "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
      "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
      "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
      "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
      "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
      "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
      "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
      "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
      "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
      "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
   ],
   "typography":{
      "htmlFontSize":16,
      "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      "fontSize":14,
      "fontWeightLight":300,
      "fontWeightRegular":400,
      "fontWeightMedium":500,
      "fontWeightBold":700,
      "h1":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":300,
         "fontSize":"6rem",
         "lineHeight":1.167,
         "letterSpacing":"-0.01562em"
      },
      "h2":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":300,
         "fontSize":"3.75rem",
         "lineHeight":1.2,
         "letterSpacing":"-0.00833em"
      },
      "h3":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":400,
         "fontSize":"3rem",
         "lineHeight":1.167,
         "letterSpacing":"0em"
      },
      "h4":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":400,
         "fontSize":"2.125rem",
         "lineHeight":1.235,
         "letterSpacing":"0.00735em"
      },
      "h5":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":400,
         "fontSize":"1.5rem",
         "lineHeight":1.334,
         "letterSpacing":"0em"
      },
      "h6":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":500,
         "fontSize":"1.25rem",
         "lineHeight":1.6,
         "letterSpacing":"0.0075em"
      },
      "subtitle1":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":400,
         "fontSize":"1rem",
         "lineHeight":1.75,
         "letterSpacing":"0.00938em"
      },
      "subtitle2":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":500,
         "fontSize":"0.875rem",
         "lineHeight":1.57,
         "letterSpacing":"0.00714em"
      },
      "body1":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":400,
         "fontSize":"1rem",
         "lineHeight":1.5,
         "letterSpacing":"0.00938em"
      },
      "body2":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":400,
         "fontSize":"0.875rem",
         "lineHeight":1.43,
         "letterSpacing":"0.01071em"
      },
      "button":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":500,
         "fontSize":"0.875rem",
         "lineHeight":1.75,
         "letterSpacing":"0.02857em",
         "textTransform":"uppercase"
      },
      "caption":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":400,
         "fontSize":"0.75rem",
         "lineHeight":1.66,
         "letterSpacing":"0.03333em"
      },
      "overline":{
         "fontFamily":"\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
         "fontWeight":400,
         "fontSize":"0.75rem",
         "lineHeight":2.66,
         "letterSpacing":"0.08333em",
         "textTransform":"uppercase"
      }
   },
   "shape":{
      "borderRadius":4
   },
   "transitions":{
      "easing":{
         "easeInOut":"cubic-bezier(0.4, 0, 0.2, 1)",
         "easeOut":"cubic-bezier(0.0, 0, 0.2, 1)",
         "easeIn":"cubic-bezier(0.4, 0, 1, 1)",
         "sharp":"cubic-bezier(0.4, 0, 0.6, 1)"
      },
      "duration":{
         "shortest":150,
         "shorter":200,
         "short":250,
         "standard":300,
         "complex":375,
         "enteringScreen":225,
         "leavingScreen":195
      }
   },
   "zIndex":{
      "mobileStepper":1000,
      "speedDial":1050,
      "appBar":1100,
      "drawer":1200,
      "modal":1300,
      "snackbar":1400,
      "tooltip":1500
   }
}
 */
