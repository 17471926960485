const myStyle = theme => ({
  blockContainer: {
    position: 'relative',
    zIndex: 10,
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    color: theme.palette.white.main,

  },
  container: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  image: {
    width: '100%',
    height: 400,
    objectFit: 'contain',
    objectPosition: 'center',
    opacity: 0,
    transform: 'scale(0.5) translateX(-300px)',
    transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
    '&.show': {
      transform: 'scale(1) translateX(0)',
      opacity: 1,
    },    
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  text: {
    paddingBottom: theme.spacing(2),
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      maxWidth: '70%',
      margin: 'auto',
    },
  },
  button: {
    marginTop: theme.spacing(2),
    '& a': {
      backgroundColor: theme.palette.yellow.main,
      borderRadius: 30,
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2.5)}px`,
      color: theme.palette.primary.main,
      transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.white.main,
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(1)}px auto 0 auto`,
    },
  },
});

export default myStyle;