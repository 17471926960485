import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';
import Anchor from '../../components/Anchor';
import Grid from '../../components/Grid';
import { InView } from 'react-intersection-observer';
import { ReactComponent as Chart1 } from '../../res/b2-charts1.svg';
import { ReactComponent as Chart2 } from '../../res/b2-charts2.svg';

import parse from '../../utils/Parser';
import myStyle from './styles';

class B2Kpis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inView3: false,
      inView4: false,
    };
    this.onView = this.onView.bind(this);
  }

  onView(name, inView) {
    this.setState(prevState => ({ [name]: inView || prevState[name] }));
  }

  renderContent() {
    const { inView3, inView4 } = this.state;
    const { classes, data,  } = this.props;

    return (
      
        <Container maxWidth="lg" className={classes.container}>
          <Box className={classes.title}>
            <Typography variant="h1">{parse(data.b2kpis.title)}</Typography>
          </Box>
          <Grid className={classes.charts}>
          <Grid.Item key="chart1" className={`${classes.chart} ${classes.chart1} ${inView3 ? 'show' : ''}`}>
            <InView onChange={inView => this.onView('inView3', inView)} triggerOnce threshold={0.5}> 
                <Typography variant="h4" className={classes.chartTitle}>{parse(data.b2kpis.titleChart1)}</Typography>
                <Chart1 className={classes.svg} />
            </InView>
          </Grid.Item>
          <Grid.Item key="chart2" className={`${classes.chart} ${classes.chart2} ${inView4 ? 'show' : ''}`}>
              <InView onChange={inView => this.onView('inView4', inView)} triggerOnce threshold={0.5}> 
                <Typography variant="h4" className={classes.chartTitle}>{parse(data.b2kpis.titleChart2)}</Typography>
                <Chart2 className={classes.svg} />
              </InView>
            </Grid.Item>
          </Grid>
        </Container>
    );
  }

  render() {
    const { classes, sectionRef } = this.props;
    return (
      <Box ref={sectionRef} className={`${classes.blockContainer} whitesection`}>
        <Anchor id="kpis" />
        {this.renderContent()}
      </Box>
    );
  }
}

B2Kpis.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  sectionRef: PropTypes.object,
  baseUrl: PropTypes.string,
};

export default withStyles(myStyle)(B2Kpis);
