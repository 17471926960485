const myStyle = theme => ({
  blockContainer: {
    position: 'relative',
    zIndex: 15,
    backgroundColor: theme.palette.blue.sky,
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
  container: {
    position: 'relative',
  },
  title: {
    position: 'relative',
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      '& h1': {
        fontSize: '2.3rem',
      },
      paddingBottom: theme.spacing(4),
    },
  },
  desc: {
    maxWidth: '70%',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  emphazise: {
    maxWidth: '80%',
    paddingBottom: theme.spacing(8),
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  content: {
    textAlign: 'center',
  },
  subtitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  grid: {
    gap: theme.spacing(4),
  },
  item: {
    textAlign: 'center',
    width: 250,
    marginBottom: theme.spacing(8),
    transform: 'translateY(100px)',
    opacity: 0,
    '& img': {
      marginBottom: theme.spacing(4),
      height: 100,
      width: 200,
      objectFit: 'contain',
    },
    '&.show': {
      transform: 'translateY(0)',
      opacity: 1,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  item1: {
    transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
  },
  item2: {
    transition: 'opacity 1s ease-in-out 0.3s, transform 1s ease-in-out 0.3s',
  },
  item3: {
    transition: 'opacity 1s ease-in-out 0.6s, transform 1s ease-in-out 0.6s',
  },
  feature: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.lighter,
  },
  descItem: {
    fontWeight: 'bold',
  },
  sculpture: {
    position: 'absolute',
    height: '100%',
    bottom: 0,
    right: 0,
    zIndex: 0,
    maxWidth: 300,
    objectFit: 'contain',
    objectPosition: 'bottom',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  plane: {
    position: 'absolute',
    zIndex: 10,
    bottom: -180,
    left: 0,
    height: 200,
    objectFit: 'contain',
    transform: 'translateX(-300px)',
    opacity: 0,
    transition: 'opacity 1s ease-in-out 0.6s, transform 1s ease-in-out 0.6s',
    '&.show': {
      transform: 'translateX(0)',
      opacity: 1,
    },
    [theme.breakpoints.down('sm')]: {
      left: 10,
      bottom: -120,
      height: 120,
    },
    [theme.breakpoints.down('xs')]: {
      left: 10,
      bottom: -110,
      height: 100,
    },
  },
});

export default myStyle;