const myStyle = theme => ({
  appBar: {
    zIndex: 1400,
    background: 'transparent',
    boxShadow: 'none',
  },
  container: {
    padding: 0,
  },
  rightItem: {
    paddingTop: `${theme.spacing(0)} !important`,
    paddingBottom: `${theme.spacing(0)} !important`,
  },
  burger: {
    height: 30,
    width: 30,
    '& line': {
      opacity: 1,
      transform: 'initial',
      stroke: theme.palette.white.main,
      transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out, stroke 0.3s ease-in-out',
    },
    '&.blueburger line': {
      stroke: theme.palette.primary.main,
    },
    '& line:nth-child(1)': {
      transformOrigin: 'left top',
    },
    '& line:nth-child(3)': {
      transformOrigin: 'left bottom',
    },
    '&.isCloseBtn line': {
      transform: 'initial',
      stroke: theme.palette.blue.main,
      transformOrigin: 'left top',
      transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out',
    },
    '&.isCloseBtn line:nth-child(1)': {
      stroke: theme.palette.white.main,
      transformOrigin: 'left top',
      transform: 'translate(10px, 0px) rotate(45deg)',
      transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out',
    },
    '&.isCloseBtn line:nth-child(2)': {
      opacity: '0',
      transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out',
    },
    '&.isCloseBtn line:nth-child(3)': {
      stroke: theme.palette.white.main,
      transformOrigin: 'left bottom',
      transform: 'translate(10px, 0px) rotate(-45deg)',
      transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out, color 0.3s ease-in-out',
    },
  },
});

export default myStyle;