import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';
import Anchor from '../../components/Anchor';
import { InView } from 'react-intersection-observer';

import parse from '../../utils/Parser';
import myStyle from './styles';

class B4Renewal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inView6: false,
      inView7: false,
      inView8: false,
      inView9: false,
    };
    this.onView = this.onView.bind(this);
  }

  onView(name, inView) {
    this.setState(prevState => ({ [name]: inView || prevState[name] }));
  }

  renderText(item) {
    const { classes } = this.props;

    return (
      <Box className={classes.text}>
        {item.title &&
          <Typography variant="h3" className={classes.subtitle}>{parse(item.title)}</Typography>
        }
        {item.text &&
          item.text.map((paragraph, idx) => {
            return <Typography key={idx} variant="body2" className={classes.text}>{parse(paragraph)}</Typography>
          })
        }
        {item.emphazise &&
          <Typography variant="body2" className={classes.emphazise}>{parse(item.emphazise)}</Typography>
        }
      </Box>
    );
  }

  renderImage(item, idx) {
    const { inView6, inView7, inView8 } = this.state;
    const { classes, baseUrl } = this.props;
    const view = `inView${idx}`;

    return (
      <Box className={classes.illus}>
        <InView onChange={inView => this.onView(view, inView)} triggerOnce threshold={0.2}> 
          {idx === 6 &&
            <img src={`${baseUrl}/blocks/${item}`} alt="" className={`${classes.illustration} ${inView6 ? 'show' : ''}`} />}
          {idx === 7 &&
            <img src={`${baseUrl}/blocks/${item}`} alt="" className={`${classes.illustration} ${inView7 ? 'show' : ''}`} />}
          {idx === 8 &&
            <img src={`${baseUrl}/blocks/${item}`} alt="" className={`${classes.illustration} ${inView8 ? 'show' : ''}`} />}
        </InView>
      </Box>
    );
  }

  renderContent() {
    const { classes, data } = this.props;

    return (
      <Container maxWidth="lg" className={classes.container}>
        <Box className={classes.title}>
          <Typography variant="h1">{parse(data.b4renewal.title)}</Typography>
        </Box>
        <Box className={classes.grid} sx={{ display: { xs: 'none', md: 'grid' } }}>
          <Box className={classes.column}>
            {this.renderImage(data.b4renewal.illustration1, 6)}
            {this.renderText(data.b4renewal.text3)}
            {this.renderText(data.b4renewal.text4)}
            {this.renderImage(data.b4renewal.illustration3, 7)}
          </Box>
          <Box className={classes.column}>
            {this.renderText(data.b4renewal.text1)}
            {this.renderText(data.b4renewal.text2)}
            {this.renderImage(data.b4renewal.illustration2, 8)}
            {this.renderText(data.b4renewal.text5)}
          </Box>
        </Box>
        <Box className={classes.contentMobile} sx={{ display: { xs: 'block', md:'none'} }}>
            {this.renderImage(data.b4renewal.illustration1, 6)}
            {this.renderText(data.b4renewal.text3)}
            {this.renderText(data.b4renewal.text4)}
            {this.renderImage(data.b4renewal.illustration2, 7)}
            {this.renderImage(data.b4renewal.text1)}
            {this.renderText(data.b4renewal.text2)}
            {this.renderImage(data.b4renewal.illustration3, 8)}
            {this.renderText(data.b4renewal.text4)}
        </Box>
      </Container>
    );
  }

  render() {
    const { inView9 } = this.state;
    const { classes, data, baseUrl } = this.props;
    return (
      <Box className={classes.blockContainer}>
        <Anchor id="renewal" />
        {this.renderContent()}
        <InView onChange={inView => this.onView('inView9', inView)} triggerOnce threshold={0.5}> 
          <img src={`${baseUrl}/blocks/${data.b4renewal.image}`} alt="" className={`${classes.image} ${inView9 ? 'show' : ''}`} />
        </InView>
      </Box>
    );
  }
}

B4Renewal.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  baseUrl: PropTypes.string,
};

export default withStyles(myStyle)(B4Renewal);
