const myStyle = theme => ({
  blockContainer: {
    position: 'relative',
    zIndex: 15,
    backgroundColor: theme.palette.blue.pale,
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
  container: {
    position: 'relative',
  },
  title: {
    position: 'relative',
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      '& h1': {
        fontSize: '2.3rem',
      },
    },
  },
  grid: {
    gridTemplateColumns: '50% 50%',
    gap: theme.spacing(8),
  },
  text: {
    maxWidth: '90%',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  subtitle: {
    color: theme.palette.white.main,
    paddingBottom: theme.spacing(2),
  },
  emphazise: {
    color: theme.palette.white.main,
    fontStyle: 'italic',
    paddingBottom: theme.spacing(2),
  },
  illustration: {
    width: '100%',
    opacity: 0,
    transform: 'scale(0.5)',
    transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',    
    '&.show': {
      transform: 'scale(1)',
      opacity: 1,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      maxWidth: '70%',
      margin: 'auto',
    },
  },
  image: {
    position: 'absolute',
    zIndex: 10,
    bottom: -100,
    right: -100,
    height: 'min(600px, 50vh)',
    maxWidth: '50vw',
    objectFit: 'contain',
    opacity: 0,
    transform: 'translateX(300px)',
    transition: 'transform 1s ease-in-out 0.5s, opacity 1s ease-in-out 0.5s',
    '&.show': {
      transform: 'translateX(0)',
      opacity: 1,
    },    
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

export default myStyle;