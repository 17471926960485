const myStyle = theme => ({
  footer: {
    position: 'relative',
    zIndex: 10,
    backgroundColor: theme.palette.primary.light,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.white.main,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  logo: {
    height: 100,
  },
});

export default myStyle;