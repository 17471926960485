const myStyle = theme => ({
  blockContainer: {
    position: 'relative',
    zIndex: 14,
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
  container: {
    position: 'relative',     
  },
  whitesection: {
  },
  title: {
    position: 'relative',
    textAlign: 'center',
    color: theme.palette.yellow.main,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      '& h1': {
        fontSize: '2.3rem',
      },
    },
  },
  chartTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.lighter,
    paddingBottom: theme.spacing(2),
  },
  charts: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      margin: 'auto'
    },
  },
  chart: {
    width: '50%',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  chart1: {
    '& .pie1-pie': {
      transition: 'opacity 1s ease-in-out 0.5s',
      opacity: 0,
    },
    '&.show .pie1-pie': {
      opacity: 1,
    },
    '& .pie1-label1': {
      transition: 'opacity 1s ease-in-out 1s',
      opacity: 0,
    },
    '&.show .pie1-label1': {
      opacity: 1,
    },
    '& .pie1-label2': {
      transition: 'opacity 1s ease-in-out 1.3s',
      opacity: 0,
    },
    '&.show .pie1-label2': {
      opacity: 1,
    },
    '& .pie1-label3': {
      transition: 'opacity 1s ease-in-out 1.6s',
      opacity: 0,
    },
    '&.show .pie1-label3': {
      opacity: 1,
    },
    '& .pie1-label4': {
      transition: 'opacity 1s ease-in-out 1.9s',
      opacity: 0,
    },
    '&.show .pie1-label4': {
      opacity: 1,
    }, 
  },
  chart2: {
    '& .pie2-pie': {
      transition: 'opacity 1s ease-in-out 0.5s',
      opacity: 0,
    },
    '&.show .pie2-pie': {
      opacity: 1,
    },
    '& .pie2-label1': {
      transition: 'opacity 1s ease-in-out 1s',
      opacity: 0,
    },
    '&.show .pie2-label1': {
      opacity: 1,
    },
    '& .pie2-label2': {
      transition: 'opacity 1s ease-in-out 1.3s',
      opacity: 0,
    },
    '&.show .pie2-label2': {
      opacity: 1,
    },
    '& .pie2-label3': {
      transition: 'opacity 1s ease-in-out 1.6s',
      opacity: 0,
    },
    '&.show .pie2-label3': {
      opacity: 1,
    },
    '& .pie2-label4': {
      transition: 'opacity 1s ease-in-out 1.9s',
      opacity: 0,
    },
    '&.show .pie2-label4': {
      opacity: 1,
    },  
  }
});

export default myStyle;