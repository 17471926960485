import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import Anchor from '../../components/Anchor';

import parse from '../../utils/Parser';
import myStyle from './styles';

class B5Board extends React.Component {
  renderText(item, idx) {
    const { classes } = this.props;

    return (
      <Box key={idx} className={classes.item}>
        {item.title && 
          <Typography variant="h3" className={classes.titleItem}>{parse(item.title)}</Typography>
        }
        {item.desc && 
          <Typography variant="body2" className={classes.textItem}>{parse(item.desc)}</Typography>
        }
      </Box>
    );
  }

  renderContent() {
    const { classes, data } = this.props;

    return (
      <Container maxWidth="lg" className={classes.container}>
        <Box className={classes.title}>
          <Typography variant="h1">{parse(data.b5board.title)}</Typography>
        </Box>
        <Box className={classes.content}>
          {data.b5board.content.map((item, idx) => this.renderText(item, idx))}
        </Box>
      </Container>
    );
  }

  render() {
    const { classes, baseUrl, data, sectionRef } = this.props;
    return (
      <Box ref={sectionRef} className={`${classes.blockContainer} whitesection`}>
        <Anchor id="board" />
        <img src={`${baseUrl}/blocks/${data.b5board.image}`} alt="" className={classes.sculpture} />
        {this.renderContent()}
      </Box>
    );
  }
}

B5Board.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  sectionsRef: PropTypes.object,
  baseUrl: PropTypes.string,
};

export default withStyles(myStyle)(B5Board);
