import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as Burger } from '../../res/burger.svg';

import Grid from '../Grid';
import Menu from '../Menu';
import myStyle from './styles';

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { menuOpen: false };
    this.onMenuOpen = this.onMenuOpen.bind(this);
  }

  onMenuOpen() {
    this.setState(prev => ({ menuOpen: !prev.menuOpen }));
  }

  render() {
    const { classes, data, baseUrl, blueBurger } = this.props;
    const { menuOpen } = this.state;
    return (
      <>
        <Menu open={menuOpen} onClose={this.onMenuOpen} data={data} baseUrl={baseUrl} className={classes.test} />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Container className={classes.container}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Grid key="right" spacing={4} itemClassName={classes.rightItem}>
                  <IconButton key="menuBtn" edge="start" color="inherit" aria-label="menu" onClick={this.onMenuOpen}>
                    <Burger className={`${this.state.menuOpen ? 'isCloseBtn' : ''} ${blueBurger ? 'blueburger' : ''} ${classes.burger}`}></Burger>
                  </IconButton>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  baseUrl: PropTypes.string,
  blueBurger: PropTypes.bool,
};

export default withStyles(myStyle)(Header);
