import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';
import Anchor from '../../components/Anchor';
import Grid from '../../components/Grid';
import { InView } from 'react-intersection-observer';
import { ReactComponent as Map } from '../../res/b3-map.svg';

import parse from '../../utils/Parser';
import myStyle from './styles';

class B3Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inView5: false,
    };
    this.onView = this.onView.bind(this);
  }

  onView(name, inView) {
    this.setState(prevState => ({ [name]: inView || prevState[name] }));
  }

  renderText(item, idx) {
    const { classes, baseUrl } = this.props;

    return (
        <Grid.Item key={idx} className={classes.item}>
          <Grid spacing={0} direction="row" wrap="nowrap" alignItems="flex-start" className={classes.item}>
            <Grid.Item key="dot">
              <img src={`${baseUrl}/common/${item.dot}`} alt="" className={classes.dot} />
            </Grid.Item>
            <Grid.Item key="text" className={classes.content}>
              {item.title &&
                <Typography variant="body2" className={classes.titleItem}>{parse(item.title)}</Typography>
              }
              {item.desc &&
                <Typography variant="body1" className={classes.descItem}>{parse(item.desc)}</Typography>
              }
            </Grid.Item>
          </Grid>
        </Grid.Item>
    );
  }

  renderContent() {
    const { inView5 } = this.state;
    const { classes, data } = this.props;

    return (
      <InView onChange={inView => this.onView('inView5', inView)} triggerOnce threshold={0.5}> 
        <Container maxWidth="lg" className={classes.container}>
          <Box className={classes.title}>
            <Typography variant="h1">{parse(data.b3map.title)}</Typography>
            <Typography variant="h1" className={classes.title2}>{parse(data.b3map.title2)}</Typography>
          </Box>
          <Box className={classes.map}>
            <Map className={`${classes.burger} ${inView5 ? 'show' : ''}`}/>
          </Box>
          <Grid spacing={0} direction="row" wrap="nowrap" alignItems="flex-start" className={classes.captions}>
            {data.b3map.caption.map((item, idx) => this.renderText(item, idx))}
          </Grid>
        </Container>
      </InView>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.blockContainer}>
        <Anchor id="map" />
        {this.renderContent()}
      </Box>
    );
  }
}

B3Map.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  baseUrl: PropTypes.string,
};

export default withStyles(myStyle)(B3Map);
