import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import Zoom from '@material-ui/core/Zoom';
import debounce from 'lodash/debounce';

import myStyle from './styles';

class ScrollTop extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.onScroll = debounce(this.onScroll.bind(this), 300);
    this.onClick = this.onClick.bind(this);
  }

  onScroll() {
    this.setState(prev => {
      if (prev.show && window.scrollY <= 50) {
        return { show: false };
      }
      if (!prev.show && window.scrollY > 50) {
        return { show: true };
      }
      return null;
    });
  }

  onClick() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    this.onScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  render() {
    const { show } = this.state;
    const { classes } = this.props;
    return (
      <Zoom unmountOnExit in={show}>
        <div className={classes.buttonContainer}>
          <ButtonBase className={classes.button} onClick={this.onClick}>
            <ArrowDropDownCircleOutlinedIcon />
          </ButtonBase>
        </div>
      </Zoom>
    );
  }
}

ScrollTop.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(myStyle)(ScrollTop);
