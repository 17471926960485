const myStyle = () => ({
  container: {
    position: 'absolute',
    width: '100%',
    pointerEvents: 'none',
    height: 0,
    left: 0,
    top: 0,
  },
});

export default myStyle;
