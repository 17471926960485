const myStyle = theme => ({
  blockContainer: {
    position: 'relative',
    zIndex: 15,
    backgroundColor: theme.palette.blue.pale,
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(8),
    paddingBottom: 300,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 150,
    },
  },
  container: {
    position: 'relative',
  },
  title: {
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      '& h1': {
        fontSize: '2.3rem',
      },
    },
  },
  admins: {
    gap: theme.spacing(4),
  },
  admin: {
    width: 180,
  },
  imageWrapper: {
    position: 'relative',
    marginBottom: 25,
    transition: 'filter 0.3s ease-in-out',
    '&.inactive': {
      pointerEvents: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
      filter: 'drop-shadow(0 0 0.75rem white)',
    },
    '&:hover img:last-child': {
      opacity: 1,
    }
  },
  image: {
    width: '100%',
    borderRadius: 25,
  },
  dot: {
    position: 'absolute',
    transform: 'translate(-50%, 50%)',
    left: '50%',
    bottom: 0,
    width: '40px',
    height: '40px',
  },
  dotHover: {
    position: 'absolute',
    transform: 'translate(-50%, 50%)',
    left: '50%',
    bottom: 0,
    width: '40px',
    height: '40px',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  name: {
    color: theme.palette.white.main,
    fontFamily: 'bely',
  },
  building: {
    position: 'absolute',
    zIndex: 10,
    bottom: 0,
    left: -100,
    width: 700,
    height: 'auto',
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      width: 600,
    },
    [theme.breakpoints.down('sm')]: {
      width: 500,
    },
    [theme.breakpoints.down('xs')]: {
      left: -50,
      width: 300,
    },
  },
  kite: {
    position: 'absolute',
    bottom: -100,
    right: 50,
    zIndex: 0,
    width: 320,
    height: 'auto',
    objectFit: 'contain',
    objectPosition: 'bottom',
    opacity: 0,
    transform: 'translateX(300px)',
    transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
    '&.show': {
      transform: 'translateX(0)',
      opacity: 1,
    },
    [theme.breakpoints.down('sm')]: {
      width: 250,
      bottom: -80,
    },
    [theme.breakpoints.down('xs')]: {
      width: 100,
      right: 10,
    },
  },
  dialogContainer: {
    position: 'relative',
    zIndex: '2000 !important',
    isolation: 'isolate',
  },
  popup: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 0,
    },
  },
  closePopup: {
    position: 'absolute !important',
    width: 'fit-content',
    right: theme.spacing(2),
    top: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      left: 'calc(50% + 90px)',
      top: '20px',
      zIndex: 5,
      '& path': {
        fill: theme.palette.white.main,
      }
    },
  },
  imageWrapperPopup: {
    position: 'relative',
  },
  imagePopup: {
    width: 300,
    borderRadius: 25,
    [theme.breakpoints.down('sm')]: {
      width: 300,
      padding: theme.spacing(2),
    },
  },
  contentPopup: {
    flexGrow: 1,
  },
  namePopup: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
  },
  content: {
    paddingBottom: theme.spacing(2),
  },
  imageDotPopup: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
    width: '40px',
    height: '40px',
  },
});

export default myStyle;