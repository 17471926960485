const myStyle = theme => ({
  blockContainer: {
    position: 'relative',
    height: '100vh',
    maxHeight: '950px',
    width: '100%',
    zIndex: 20,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(4),
  },
  title: {
    position: 'relative',
    height: 800,
    width: 800,
    maxHeight: '80vh',
    maxWidth: '80vw',
    objectFit: 'contain',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'contain',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80vw',
    },
  },
  text: {
    position: 'absolute',
    color: theme.palette.yellow.main,
    bottom: 'calc(50% + 3vh)',
    left: 'calc(60% + 3vw)',
    [theme.breakpoints.down('md')]: {
      bottom: '70%',
      maxWidth: '50vw',
    },
  },
  text3: {
    textIndent: theme.spacing(6),
  },
  montgolfiere: {
    position: 'absolute',
    zIndex: 10,
    bottom: -132,
    left: 0,
    height: 350,
    objectFit: 'contain',
    opacity: 0,
    transform: 'scale(0.5) translateX(-300px)',
    transition: 'transform 1s ease-in-out 0.5s, opacity 1s ease-in-out 0.5s',
    '&.show': {
      transform: 'scale(1) translateX(0)',
      opacity: 1,
    },
    [theme.breakpoints.down('sm')]: {
      left: 30,
      bottom: -45,
      height: 300,
    },
    [theme.breakpoints.down('xs')]: {
      left: 10,
      bottom: -30,
      height: 150,
    },
  },
  logo: {
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
    width: 150,
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
});

export default myStyle;