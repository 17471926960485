import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import parse from '../../utils/Parser';

import myStyle from './styles';
import { Box } from '@mui/material';

class Menu extends React.PureComponent {
  
  renderItem(item) {
    const { classes, onClose } = this.props;
    return (
      <div key={item.num} className={classes.itemContainer}>
        <div className={classes.itemContent}>
          <ButtonBase
            key={item.num}
            className={classes.itemButton}
            onClick={onClose}
            component="a"
            href={`#${item.anchor}`}
          >
            <Typography variant="body2" className={classes.itemTitle}>
              {parse(item.title)}
            </Typography>
          </ButtonBase>
        </div>
      </div>
    );
  }

  render() {
    const { classes, data, baseUrl, open, onClose } = this.props;
    return (
      <Dialog fullScreen open={open} onClose={onClose} PaperProps={{ square: true, className: classes.paper }}>
        <Container maxWidth="lg" className={classes.container}>
          <Box sx={{
            display: {sm: 'flex', md: 'grid'},
            flexDirection: 'column-reverse',
            width: '100%',
            gridTemplateAreas: { xs: "'menu'", sm: "'menu picture'", md: "'menu picture'"},
            gridTemplateColumns: {xs: '1fr', md: '1fr 1fr'},
            justifyItems: 'center',
            alignItems: 'flex-start',
            justifyContent: {xs: 'center', lg: 'start'},
            alignContent: 'center',
            gap: {xs: 0, sm: 4}
          }}>
          <div className={classes.grid} spacing={1}>
            <Typography variant="h3" className={classes.title}>
              {parse(data.menu.title)}
            </Typography>
            {data.menu.items.map(item => this.renderItem(item))}
          </div>
          <img src={`${baseUrl}/common/${data.menu.image}`} alt="" className={classes.illustration} />
          </Box>
        </Container>
      </Dialog>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  baseUrl: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withStyles(myStyle)(Menu);
