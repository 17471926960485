const myStyle = theme => ({
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
      alignItems: 'center',
    },
  },
  grid: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gridArea: 'menu',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(8),
      height: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      // height: '%',
      paddingLeft: 0,
    },
  },
  title: {
    fontSize: '4.8125rem',
    color: theme.palette.yellow.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },
  itemTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.yellow.main,
    },
  },
  illustration: {
    maxHeight: '80vh',
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    gridArea: 'picture',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '40vh',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '30vh',
      objectPosition: 'right',
      marginTop: theme.spacing(4),
    },
  },
});

export default myStyle;