const myStyle = theme => ({
  blockContainer: {
    position: 'relative',
    zIndex: 14,
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
    '& .stronger': {
      fontFamily: 'bely',
    }
  },
  container: {
    position: 'relative',
    paddingLeft: '25%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  title: {
    position: 'relative',
    color: theme.palette.yellow.main,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      '& h1': {
        fontSize: '2.3rem',
      },
    },
  },
  desc: {
    maxWidth: '70%',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  item: {
    paddingBottom: theme.spacing(4),
  },
  subtitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  sculpture: {
    position: 'absolute',
    height: '100%',
    bottom: 0,
    left: 0,
    zIndex: 0,
    transform: 'translateX(-20%)',
    objectFit: 'contain',
    objectPosition: 'bottom',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

export default myStyle;