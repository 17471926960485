import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import myStyle from './styles';

class Footer extends React.PureComponent {
  render() {
    const { classes, baseUrl } = this.props;
    return (
      <div className={classes.footer}>
        <Container className={classes.container}>
          <div className={classes.footerContent}>
            <img key="logo" className={classes.logo} src={`${baseUrl}/common/logo-cfe.svg`} alt="Logo CFE" />
          </div>
        </Container>
      </div>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
};

export default withStyles(myStyle)(Footer);
