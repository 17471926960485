const myStyle = theme => ({
  buttonContainer: {
    position: 'fixed',
    color: theme.palette.yellow.main,
    zIndex: 20,
    bottom: theme.spacing(8),
    right: theme.spacing(4),
    borderRadius: '50%',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  button: {
    borderRadius: '50%',
    overflow: 'hidden',
    transform: 'rotate(180deg) scale(3)',
    [theme.breakpoints.down('sm')]: {
      width: '40px !important',
      height: '40px !important',
    },
    '& svg': {
      [theme.breakpoints.down('sm')]: {
        width: 18,
      },
    },
  },
});
export default myStyle;
