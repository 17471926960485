import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import theme from '../../utils/Theme';
import Header from '../../components/Header';
import B0Intro from '../../blocks/B0Intro';
import B1Reason from '../../blocks/B1Reason';
import B2Kpis from '../../blocks/B2Kpis';
import B3Map from '../../blocks/B3Map';
import B4Renewal from '../../blocks/B4Renewal';
import B5Board from '../../blocks/B5Board';
import B6Admin from '../../blocks/B6Admin';
import B7Satisfaction from '../../blocks/B7Satisfaction';
import Footer from '../../components/Footer';

import ScrollTop from '../../components/ScrollTop';
import myStyle from './styles';

const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      error: null,
      blueBurger: false,
      sectionsRefs : {
        b1: React.createRef(),
        b2: React.createRef(),
        b5: React.createRef(),
      },
    };
    this.blueBurgerRef = false;
    this.setBlueBurger = this.setBlueBurger.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  onScroll() {
    const { b1, b2, b5 } = this.state.sectionsRefs;
    const whiteSections = [b1.current, b2.current, b5.current];
    let logoIsBlue = false;
    whiteSections.forEach(whiteSection => {
      if (whiteSection !== null) {
        const sectionDimension = whiteSection.getBoundingClientRect();
        if (sectionDimension) {
          const sectionHeight = sectionDimension['height'];
          const sectionY = sectionDimension['y'] - 40;
          if (sectionY < 0 && Math.abs(sectionY) < sectionHeight) {
            logoIsBlue = true;
            if (!this.blueBurgerRef) {
              this.blueBurgerRef = true;
              this.setState({ blueBurger: true });
              return;
            }
          } 
        }
      }
    });
    if (this.blueBurgerRef && !logoIsBlue) {
      this.blueBurgerRef = false;
      this.setState({ blueBurger: false });
    }
  }

  setBlueBurger(isBlue) {
    this.setState({ blueBurger: isBlue });
  }

  async componentDidMount() {
    const resp = await fetch('./data.json', { cache: 'no-store' });
    const json = await resp.text();
    let data;
    try {
      data = JSON.parse(json);
    } catch (error) {
      this.setState({ error });
      return;
    }
    this.setState({ data }, () => {
      window.addEventListener('scroll', this.onScroll.bind(this));
      this.onScroll();
    });
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }
    return (
      <div style={{ padding: 30 }}>
        <h2>Erreur dans le parsing du fichier</h2>
        <pre>{error.message}</pre>
      </div>
    );
  }

  renderLoading() {
    const { data, error } = this.state;
    if (data || error) {
      return null;
    }
    return (
      <div style={{ width: '100%', paddingTop: '35vh', textAlign: 'center' }}>
        <h1 style={{ width: '100%', textAlign: 'center', marginBottom: 50 }}>Chargement...</h1>
        <CircularProgress />
      </div>
    );
  }

  renderApp() {
    const { data: allData, error, blueBurger, sectionsRefs } = this.state;
    const { classes } = this.props;
    if (!allData || error) {
      return null;
    }
    const data = allData;
    return (
      <div className={classes.mainContainer}>
        <Header data={data} baseUrl={BASE_URL} blueBurger={blueBurger} />
        <B0Intro data={data} baseUrl={BASE_URL} />
        <B1Reason data={data} baseUrl={BASE_URL} sectionRef={sectionsRefs.b1} />
        <B2Kpis data={data} baseUrl={BASE_URL} sectionRef={sectionsRefs.b2}/>
        <B3Map data={data} baseUrl={BASE_URL} />
        <B4Renewal data={data} baseUrl={BASE_URL} />
        <B5Board data={data} baseUrl={BASE_URL} sectionRef={sectionsRefs.b5} />
        <B6Admin data={data} baseUrl={BASE_URL} />
        <B7Satisfaction data={data} baseUrl={BASE_URL} />
        <ScrollTop data={data} baseUrl={BASE_URL} />
        <Footer data={data} baseUrl={BASE_URL} /> 
      </div>
    );
  }

  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {this.renderLoading()}
          {this.renderError()}
          {this.renderApp()} 
        </ThemeProvider>
      </>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(myStyle)(App);

